import React, { useEffect } from "react";
import { Button } from "../../../../shared/components/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../../shared/components/drawer/Drawer";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../shared/components/tabs/Tabs";
import { Download, File, Trash2 } from "lucide-react";
import { Badge, BadgeProps } from "../../../../shared/components/badge/Badge";
import { ArrayElement } from "../../../../shared/utils";
import { UseOutreachAgentsByWorkspaceId } from "../../../../shared/domains/channels/graphql/hooks/useOutreachAgentsByWorkspaceId";
import { UseOutreachAgentTypes } from "../../../../shared/domains/static/graphql/hooks/useOutreachAgentTypes";
import { UseOutreachPlatforms } from "../../../../shared/domains/static/graphql/hooks/useOutreachPlatforms";
import { UseCampaignExternalConnectionProviders } from "../../../../shared/domains/static/graphql/hooks/useCampaignExternalConnectionProviders";
import { UpdateOutreachAgentValues } from "../validation";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { Switch } from "../../../../shared/components/switch/Switch";

interface DataTableDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>;
  /// dependencies for update outreach agent form
  outreachAgentTypes: UseOutreachAgentTypes["outreachAgentTypes"];
  outreachPlatforms: UseOutreachPlatforms["outreachPlatforms"];
  campaignExternalConnectionProviders: UseCampaignExternalConnectionProviders["campaignExternalConnectionProviders"];
  // dependency to disable the form
  updateOutreachAgentMutationLoading: boolean;
  // dependency to submit the form
  updateOutreachAgentOnSubmit: (data: UpdateOutreachAgentValues) => Promise<void>;
  // dependency to display errors & set form values
  updateOutreachAgentErrors: FieldErrors<UpdateOutreachAgentValues>;
  updateOutreachAgentControl: Control<UpdateOutreachAgentValues, any, UpdateOutreachAgentValues>;
  updateOutreachAgentRegister: UseFormRegister<UpdateOutreachAgentValues>;
  updateOutreachAgentHandleSubmit: UseFormHandleSubmit<
    UpdateOutreachAgentValues,
    UpdateOutreachAgentValues
  >;
  updateOutreachAgentSetValue: UseFormSetValue<UpdateOutreachAgentValues>;
}

export function DataTableDrawer({
  open,
  onOpenChange,
  data,
  updateOutreachAgentHandleSubmit,
  updateOutreachAgentOnSubmit,
  outreachAgentTypes,
  outreachPlatforms,
  campaignExternalConnectionProviders,
  updateOutreachAgentControl,
  updateOutreachAgentRegister,
  updateOutreachAgentErrors,
  updateOutreachAgentSetValue,
}: DataTableDrawerProps) {
  const isActive = data.is_active === true;
  const isVerified = data.is_verified === true;

  useEffect(() => {
    if (data.id) {
      updateOutreachAgentSetValue("isActive", data.is_active);
      updateOutreachAgentSetValue("outreachAgentId", data.id);
    }
  }, [data.id]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {data ? (
        <DrawerContent className="flex flex-col h-full  max-h-[98vh] overflow-x-hidden sm:max-w-lg dark:bg-gray-925">
          <DrawerHeader className="-px-6 w-full">
            <DrawerTitle className="flex w-full items-center justify-between">
              <span>Edit Channel</span>
            </DrawerTitle>
            <div className="mt-1 flex items-center justify-start space-x-2">
              <span className="text-left text-sm text-gray-500 dark:text-gray-500">
                <Badge variant={isActive ? "success" : "error"}>
                  {isActive ? "Active" : "Inactive"}
                </Badge>
              </span>
              <Badge variant={isVerified ? "success" : "error"}>
                {isVerified ? "Verified" : "Unverified"}
              </Badge>
            </div>
          </DrawerHeader>
          <form
            onSubmit={updateOutreachAgentHandleSubmit(updateOutreachAgentOnSubmit)}
            className="flex flex-col h-full"
          >
            <DrawerBody className="flex-grow overflow-y-auto -mx-6 space-y-4 px-6 pb-6">
              <div>
                <Label htmlFor="role-new-user" className="font-medium">
                  Platform
                </Label>
                <Controller
                  control={updateOutreachAgentControl}
                  name="platformId"
                  defaultValue={data.outreach_platform.id.toString()}
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger id="platform" name="platform" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent align="end">
                          {outreachPlatforms.map(platform => (
                            <SelectItem key={platform.id} value={platform.id?.toString()}>
                              {platform.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    );
                  }}
                ></Controller>
                {updateOutreachAgentErrors.platformId && (
                  <ErrorMessage>{updateOutreachAgentErrors.platformId.message}</ErrorMessage>
                )}
              </div>
              <div>
                <Label htmlFor="role-new-user" className="font-medium">
                  Provider
                </Label>
                <Controller
                  control={updateOutreachAgentControl}
                  name="providerId"
                  defaultValue={data.campaign_external_connection_provider.id.toString()}
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger id="provider" name="provider" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent align="end">
                          {campaignExternalConnectionProviders.map(platform => (
                            <SelectItem key={platform.id} value={platform.id?.toString()}>
                              {platform.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    );
                  }}
                ></Controller>
                {updateOutreachAgentErrors.providerId && (
                  <ErrorMessage>{updateOutreachAgentErrors.providerId.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Label htmlFor="role-new-user" className="font-medium">
                  Type
                </Label>
                <Controller
                  control={updateOutreachAgentControl}
                  name="typeId"
                  defaultValue={data.outreach_agent_type.id.toString()}
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger id="type" name="type" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent align="end">
                          {outreachAgentTypes.map(platform => (
                            <SelectItem key={platform.id} value={platform.id?.toString()}>
                              {platform.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    );
                  }}
                ></Controller>
                {updateOutreachAgentErrors.typeId && (
                  <ErrorMessage>{updateOutreachAgentErrors.typeId.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Label htmlFor="name" className="font-medium">
                  Identifier
                </Label>
                <p className="text-xs -mt-2 leading-6 text-gray-500">
                  Please input the platform username (e.g. Email for Linkedin)
                </p>
                <Input
                  {...updateOutreachAgentRegister("identifier")}
                  type="text"
                  defaultValue={data.platform_identifier}
                  className="mt-2"
                />
                {updateOutreachAgentErrors.identifier && (
                  <ErrorMessage>{updateOutreachAgentErrors.identifier.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Label htmlFor="name" className="font-medium">
                  External Id
                </Label>
                <Input
                  {...updateOutreachAgentRegister("externalId")}
                  type="number"
                  defaultValue={data.external_id ? data.external_id : undefined}
                  className="mt-2"
                />
                {updateOutreachAgentErrors.externalId && (
                  <ErrorMessage>{updateOutreachAgentErrors.externalId.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Label htmlFor="name" className="font-medium">
                  External Credential Id
                </Label>
                <p className="text-xs leading-2 text-gray-500">
                  The id of the 1Password credential for this channel (no passwords are stored in
                  the system). Keep empty if no 1Password entry is available.
                </p>
                <Input
                  {...updateOutreachAgentRegister("externalCredentialId")}
                  type="text"
                  className="mt-2"
                  defaultValue={
                    data.external_credential_id ? data.external_credential_id : undefined
                  }
                />
                {updateOutreachAgentErrors.externalCredentialId && (
                  <ErrorMessage>
                    {updateOutreachAgentErrors.externalCredentialId.message}
                  </ErrorMessage>
                )}
              </div>
            </DrawerBody>
            <DrawerFooter className="flex-shrink-0 -mx-6 -mb-2 gap-2 bg-white px-6 dark:bg-gray-925">
              <DrawerClose>
                <Button type="button" variant="secondary" className="w-full">
                  Cancel
                </Button>
              </DrawerClose>

              <Button type="submit" className="w-full sm:max-w-[4.5rem]">
                Submit
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      ) : null}
    </Drawer>
  );
}
