import { getRealtorsPaginated } from "../queries/getRealtorsPaginated";
import { useQuery } from "@apollo/client";
import * as faker from "faker";
import { REALTOR_STATUS } from "../../../../constants/models";
export type UseRealtorsPaginated = ReturnType<typeof useRealtorsPaginated>;

export const useRealtorsPaginated = () => {
  const { data, loading, error, refetch } = useQuery<any>(getRealtorsPaginated);

  const realtors = Array(10)
    .fill(null)
    .map((_, index) => ({
      id: index + 1,
      name: faker.name.findName(),
      email: faker.internet.email(),
      homeowner_network: faker.datatype.number({ min: 100, max: 1000 }),
      status_id: index % 2 === 0 ? REALTOR_STATUS.ACTIVE : REALTOR_STATUS.INACTIVE,
    }));
  const realtorsCount = realtors.length;
  return {
    realtorsCount,
    realtors,
    // realtorsCount: data?.zipcode_aggregate?.aggregate?.count || 0,
    // realtors: data && Array.isArray(data.zipcode) ? data.zipcode : [],
    realtorsError: error,
    realtorsLoading: loading,
    refetchRealtors: refetch,
  };
};
