import { useSearchParams } from "react-router-dom";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import { Label } from "../../../shared/components/label/Label";
import { WORKSPACE_STATUS } from "../../../shared/constants/models";
import { UseWorkspaces } from "../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { WorkspaceStatusCheckboxProps } from "../types";
import { useMemo, useState } from "react";
import { useDebounce } from "../../../shared/hooks/UseDebounce";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shared/components/popover/Popover";
import { cx } from "../../../shared/utils";
import { Button } from "../../../shared/components/button/Button";
import { Input } from "../../../shared/components/input/Input";

export const QUERY_PARAM_WORKSPACES_IDS = "workspaces-ids";

const WorkspaceStatusCheckbox = ({
  workspaceStatus,
  onSelectionChange,
}: WorkspaceStatusCheckboxProps) => {
  const allSelected = workspaceStatus.workspaces.every(workspace => workspace.selected);
  const someSelected = workspaceStatus.workspaces.some(workspace => workspace.selected);

  const handleWorkspaceStatusChange = (checked: boolean) => {
    const updatedWorkspaces = workspaceStatus.workspaces.map(workspace => workspace.id);
    onSelectionChange(workspaceStatus.name, checked ? updatedWorkspaces : []);
  };

  const handleWorkspaceChange = (workspaceId: number, checked: boolean) => {
    const updatedWorkspaces = workspaceStatus.workspaces
      .filter(workspace => workspace.selected || workspace.id === workspaceId)
      .map(workspace => workspace.id);

    if (!checked) {
      const index = updatedWorkspaces.indexOf(workspaceId);
      if (index > -1) updatedWorkspaces.splice(index, 1);
    }

    onSelectionChange(workspaceStatus.name, updatedWorkspaces);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="sticky top-0 z-50 flex items-center gap-2 rounded-md bg-gray-100 px-1 py-1 dark:bg-gray-900">
        <Checkbox
          id={workspaceStatus.name}
          checked={allSelected ? true : someSelected ? "indeterminate" : false}
          onCheckedChange={handleWorkspaceStatusChange}
        />
        <Label className="w-full text-base sm:text-sm font-medium" htmlFor={workspaceStatus.name}>
          {workspaceStatus.name}
        </Label>
      </div>
      <div className="ml-4 flex flex-col gap-2">
        {workspaceStatus.workspaces.map(workspace => (
          <div key={workspace.name} className="flex items-center gap-2">
            <Checkbox
              id={workspace.name}
              checked={workspace.selected}
              onCheckedChange={(checked: boolean) => handleWorkspaceChange(workspace.id, checked)}
            />
            <Label className="text-base sm:text-sm w-full" htmlFor={workspace.name}>
              {workspace.name}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};

export interface FilterWorkspaceProps {
  workspaces: UseWorkspaces["workspaces"];
}

function FilterWorkspaces({ workspaces }: FilterWorkspaceProps) {
  const groupedWorkspaces = [
    {
      name: "Active",
      workspaces: workspaces.filter(
        workspace => workspace.workspace_status_id === WORKSPACE_STATUS.ACTIVE
      ),
    },
    {
      name: "Inactive",
      workspaces: workspaces.filter(
        workspace => workspace.workspace_status_id === WORKSPACE_STATUS.INACTIVE
      ),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  // Retrieve and parse the selectedCountries from the query string
  const selectedWorkspacesIdsString = searchParams.get(QUERY_PARAM_WORKSPACES_IDS);
  const selectedWorkspacesIds = selectedWorkspacesIdsString
    ? selectedWorkspacesIdsString.split(",").map(id => parseInt(id))
    : [];

  // Function to update the selectedCountries in the query string
  const setSelectedWorkspaces = (workspacesIds: number[]) => {
    if (workspacesIds.length > 0) {
      searchParams.set(QUERY_PARAM_WORKSPACES_IDS, workspacesIds.join(","));
      setSearchParams(searchParams);
    } else {
      searchParams.delete(QUERY_PARAM_WORKSPACES_IDS);
      setSearchParams(searchParams);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const workspaceStatuses = useMemo(() => {
    return groupedWorkspaces.map(group => ({
      name: group.name,
      workspaces: group.workspaces.map(workspace => ({
        id: workspace.id,
        name: workspace.name,
        selected: selectedWorkspacesIds.includes(workspace.id),
      })),
    }));
  }, [selectedWorkspacesIds]);

  const filteredWorkspaceStatuses = useMemo(() => {
    return workspaceStatuses
      .map(workspaceStatus => ({
        ...workspaceStatus,
        workspaces: workspaceStatus.workspaces.filter(workspace =>
          workspace.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ),
      }))
      .filter(
        workspaceStatus =>
          workspaceStatus.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          workspaceStatus.workspaces.length > 0
      );
  }, [workspaceStatuses, debouncedSearchTerm]);

  const handleSelectionChange = (workspaceStatus: string, workspacesIds: number[]) => {
    const otherSelectedWorkspaces = selectedWorkspacesIds.filter(
      workspaceId =>
        !groupedWorkspaces
          .find(group => group.name === workspaceStatus)
          ?.workspaces.some(groupedWorkspace => groupedWorkspace.id === workspaceId)
    );

    setSelectedWorkspaces([...otherSelectedWorkspaces, ...workspacesIds]);
  };

  return (
    <div>
      <Label htmlFor="location-filter" className="block font-medium">
        Workspaces
      </Label>
      <Popover modal={true}>
        <PopoverTrigger asChild className="mt-3 w-full md:w-fit" id="location-filter">
          <Button
            variant="secondary"
            className={cx(
              "flex justify-start gap-1.5 font-normal md:justify-center dark:bg-[#090E1A] hover:dark:bg-gray-950/50"
            )}
          >
            Selected Workspaces
            <span className="flex shrink-0 items-center justify-center rounded bg-gray-200 px-1 tabular-nums text-gray-900 dark:bg-gray-800 dark:text-gray-50">
              {selectedWorkspacesIds.length}
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="z-50 min-w-[calc(var(--radix-popover-trigger-width))] max-w-[calc(var(--radix-popover-trigger-width))] sm:min-w-56 sm:max-w-56"
          align="end"
        >
          <div className="flex h-full max-h-96 flex-col gap-3">
            <Input
              placeholder="Search for continent or country"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="sm:[&>input]:py-1.5"
            />
            <div className="flex-grow overflow-y-auto">
              <div className={filteredWorkspaceStatuses.length > 0 ? "space-y-4" : ""}>
                {filteredWorkspaceStatuses.length > 0 ? (
                  filteredWorkspaceStatuses.map(workspaceStatus => (
                    <WorkspaceStatusCheckbox
                      key={workspaceStatus.name}
                      workspaceStatus={workspaceStatus}
                      onSelectionChange={handleSelectionChange}
                    />
                  ))
                ) : (
                  <span className="mt-2 block text-base sm:text-sm text-gray-500 dark:text-gray-500">
                    No results found
                  </span>
                )}
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export { FilterWorkspaces };
