import { gql } from "@apollo/client";

export const getRealtors = gql`
  query getRealtors {
    realtors {
      id
      name
      email
    }
  }
`;
