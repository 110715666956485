import {
  editWorkspaceBlacklistedAgents,
  EditWorkspaceBlacklistedAgentsValues,
  updateWorkspaceValidationSchema,
  UpdateWorkspaceValues,
} from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useUpdateWorkspaceAPI } from "../../../../shared/domains/workspaces/REST/updateWorkspaceAPI";
import { Divider } from "../../../../shared/components/divider/Divider";
import { useZipcodeById } from "../../../../shared/domains/postcodes/zipcodes/hooks/useZipcodeById";
import ZipcodeLaunchSteps from "../components/ZipcodeLaunchSteps";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ZIPCODE_STATUS } from "../../../../shared/constants/models";
import { Button } from "../../../../shared/components/button/Button";

export default function SingleZipcodeGeneral() {
  // params
  let { zipcodeId } = useParams<{ zipcodeId: string }>();
  const [isLaunched, setIsLaunched] = useState(false);

  // // non lazy hooks
  // const { workspaceStatuses } = useWorkspaceStatuses();
  const { zipcode, zipcodeLoading, refetchZipcode } = useZipcodeById({
    id: Number(zipcodeId),
  });

  // api mutations

  const {
    mutateAsync: updateWorkspaceMutation,
    isSuccess: updateWorkspaceMutationSuccess,
    isLoading: updateWorkspaceMutationLoading,
  } = useUpdateWorkspaceAPI();

  // use effects

  useEffect(() => {
    if (updateWorkspaceMutationSuccess) {
      refetchZipcode();
    }
  }, [updateWorkspaceMutationSuccess]);

  // form related

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateWorkspaceValues>({
    resolver: yupResolver(updateWorkspaceValidationSchema),
    defaultValues: zipcode
      ? {
          workspaceStatusId: String(zipcode?.status_id),
          name: zipcode?.code,
        }
      : undefined,
  });

  const submitInformationUpdate = (data: UpdateWorkspaceValues) => {
    updateWorkspaceMutation({
      workspaceId: Number(zipcodeId),
      workspaceName: data.name,
      workspaceStatusId: Number(data.workspaceStatusId),
    });
  };

  const {
    handleSubmit: handleSubmitBlacklistedAgents,
    control: controlBlacklistedAgents,
    reset: resetBlacklistedAgents,
    formState: { errors: blacklistedAgentsErrors },
  } = useForm<EditWorkspaceBlacklistedAgentsValues>({
    resolver: yupResolver(editWorkspaceBlacklistedAgents),
  });

  return (
    <div className="space-y-10 pb-20">
      <section aria-labelledby="workspace-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              id="audit-rules-heading"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Launch
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-500">
              Launching this zipcode will trigger a workflow that acquires and matches addresses,
              owners and profiles.
            </p>
          </div>
          <div className="md:col-span-2">
            {
              /*zipcode.status_id === ZIPCODE_STATUS.INACTIVE*/ !isLaunched ? (
                <Button
                  variant="primary"
                  className="mt-4 w-full gap-2 sm:mt-0 sm:w-fit"
                  onClick={() => setIsLaunched(true)}
                >
                  Launch
                </Button>
              ) : (
                <ZipcodeLaunchSteps status="Active" />
              )
            }
          </div>
        </div>
      </section>
    </div>
  );
}
