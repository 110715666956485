import { Button } from "../../../../shared/components/button/Button";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { useWorkspaceStatuses } from "../../../../shared/domains/static/graphql/hooks/useWorkspaceStatuses";
import {
  editWorkspaceBlacklistedAgents,
  EditWorkspaceBlacklistedAgentsValues,
  updateWorkspaceValidationSchema,
  UpdateWorkspaceValues,
} from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useWorkspaceById } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaceById";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useEffect, useState } from "react";
import { useUpdateWorkspaceAPI } from "../../../../shared/domains/workspaces/REST/updateWorkspaceAPI";
import { Divider } from "../../../../shared/components/divider/Divider";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Plus, Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "../../../../shared/components/table/Table";
import { reactSelectStyling } from "../../../../shared/styles";
import {
  addOrRemoveBlacklistedAgentsAPI,
  useAddOrRemoveBlacklistedAgents,
} from "../../../../shared/domains/workspaces/REST/addOrRemoveBlacklistedAgentAPI";
import ReactSelect from "react-select";

export default function SingleRealtorGeneral() {
  // params
  let { workspaceId } = useParams<{ workspaceId: string }>();
  const [isBlacklistedAgentDialogOpen, setIsBlacklistedAgentDialogOpen] = useState(false);

  // non lazy hooks
  const { workspaceStatuses } = useWorkspaceStatuses();
  const { workspace, workspaceLoading, refetchWorkspace } = useWorkspaceById({
    id: Number(workspaceId),
  });

  // api mutations

  const {
    mutateAsync: updateWorkspaceMutation,
    isSuccess: updateWorkspaceMutationSuccess,
    isLoading: updateWorkspaceMutationLoading,
  } = useUpdateWorkspaceAPI();

  const {
    mutateAsync: addBlacklistedAgentMutation,
    isSuccess: addBlacklistedAgentMutationSuccess,
    isLoading: addBlacklistedAgentMutationLoading,
  } = useAddOrRemoveBlacklistedAgents();

  // use effects

  useEffect(() => {
    if (updateWorkspaceMutationSuccess || addBlacklistedAgentMutationSuccess) {
      refetchWorkspace();
    }
  }, [updateWorkspaceMutationSuccess, addBlacklistedAgentMutationSuccess]);

  useEffect(() => {
    if (!workspaceLoading && !updateWorkspaceMutationLoading && workspace) {
      reset({
        workspaceStatusId: String(workspace.workspace_status_id),
        name: workspace.name,
      });
    }
  }, [workspace, updateWorkspaceMutationLoading, workspaceLoading]);

  useEffect(() => {
    if (addBlacklistedAgentMutationSuccess) {
      setIsBlacklistedAgentDialogOpen(false);
      resetBlacklistedAgents(undefined);
    }
  }, [addBlacklistedAgentMutationSuccess]);

  // form related

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateWorkspaceValues>({
    resolver: yupResolver(updateWorkspaceValidationSchema),
    defaultValues: workspace
      ? {
          workspaceStatusId: String(workspace?.workspace_status_id),
          name: workspace?.name,
        }
      : undefined,
  });

  const submitInformationUpdate = (data: UpdateWorkspaceValues) => {
    updateWorkspaceMutation({
      workspaceId: Number(workspaceId),
      workspaceName: data.name,
      workspaceStatusId: Number(data.workspaceStatusId),
    });
  };

  const onBlacklistedAgentSubmit = async (values: EditWorkspaceBlacklistedAgentsValues) => {
    await addBlacklistedAgentMutation({
      workspaceId: Number(workspaceId),
      branchAgentIds: values.branchAgents.map(agent => agent.value),
      parentAgentIds: [],
      action: "addExclusions",
    });
  };

  const {
    handleSubmit: handleSubmitBlacklistedAgents,
    control: controlBlacklistedAgents,
    reset: resetBlacklistedAgents,
    formState: { errors: blacklistedAgentsErrors },
  } = useForm<EditWorkspaceBlacklistedAgentsValues>({
    resolver: yupResolver(editWorkspaceBlacklistedAgents),
  });

  return (
    <>
      <div className="space-y-10 pb-20">
        <section aria-labelledby="workspace-information">
          <form onSubmit={handleSubmit(submitInformationUpdate)}>
            <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
              <div>
                <h2
                  id="workspace-information"
                  className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
                >
                  Workspace information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Manage workspace information.
                </p>
              </div>
              <div className="md:col-span-2">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="col-span-full sm:col-span-3">
                    <Label htmlFor="name" className="font-medium">
                      Name
                    </Label>
                    <Input
                      type="text"
                      {...register("name")}
                      placeholder="Agency"
                      className="mt-2"
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-full sm:col-span-3">
                    <Label htmlFor="Workspace-status" className="font-medium">
                      Status
                    </Label>
                    <Controller
                      control={control}
                      name="workspaceStatusId"
                      render={({ field }) => {
                        return (
                          <Select onValueChange={field.onChange} {...field}>
                            <SelectTrigger id="Workspace-status" className="mt-2 w-full">
                              <SelectValue placeholder="Workspace Status" />
                            </SelectTrigger>
                            <SelectContent>
                              {workspaceStatuses.map(item => (
                                <SelectItem key={item.id} value={item.id?.toString()}>
                                  {item.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        );
                      }}
                    ></Controller>
                    {errors.workspaceStatusId && (
                      <ErrorMessage>{errors.workspaceStatusId.message}</ErrorMessage>
                    )}
                  </div>

                  <div className="col-span-full mt-6 flex justify-end">
                    <Button type="submit" disabled={updateWorkspaceMutationLoading}>
                      Update Information
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <Divider />
      </div>
    </>
  );
}
