import { useParams } from "react-router-dom";
import { Button } from "../../../../shared/components/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { RiAddLine, RiMore2Fill } from "@remixicon/react";
import { useWorkspaceById } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaceById";
import { useUsers } from "../../../../shared/domains/users/graphql/hooks/useUsers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTeamMemberValidationSchema, AddTeamMemberValues } from "../validation";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Label } from "../../../../shared/components/label/Label";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useEffect } from "react";
import React from "react";
import { useAddOrRemoveTeamMembersAPI } from "../../../../shared/domains/workspaces/REST/addOrRemoveTeamMemberAPI";

export default function SingleRealtorUsers() {
  let { workspaceId } = useParams<{ workspaceId: string }>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { workspace, refetchWorkspace } = useWorkspaceById({
    id: Number(workspaceId),
  });
  const {
    mutateAsync: addOrRemoveTeamMembersMutation,
    isSuccess: addOrRemoveTeamMembersMutationSuccess,
    isLoading: addOrRemoveTeamMembersMutationLoading,
  } = useAddOrRemoveTeamMembersAPI();
  const { users } = useUsers();
  const existingUsers = workspace?.workspace_users.map(user => user.user.id);
  const nonExistingUsers = users.filter(user => !existingUsers?.includes(user.id));

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AddTeamMemberValues>({
    resolver: yupResolver(addTeamMemberValidationSchema),
  });

  useEffect(() => {
    if (addOrRemoveTeamMembersMutationSuccess) {
      setIsDialogOpen(false);
      refetchWorkspace();
      reset(undefined);
    }
  }, [addOrRemoveTeamMembersMutationSuccess]);

  const addTeamMemberOnSubmit = async (data: AddTeamMemberValues) => {
    await addOrRemoveTeamMembersMutation({
      workspaceId: Number(workspaceId),
      userIdsToAdd: [Number(data.userId)],
      userIdsToRemove: [],
    });
  };

  const removeTeamMember = async (userId: number) => {
    await addOrRemoveTeamMembersMutation({
      workspaceId: Number(workspaceId),
      userIdsToRemove: [userId],
      userIdsToAdd: [],
    });
  };

  return (
    <>
      <section aria-labelledby="existing-users">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3
              id="existing-users"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Team Members
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              You can add, manage, and remove users from this workspace.
            </p>
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button className="mt-4 w-full gap-2 sm:mt-0 sm:w-fit">
                <RiAddLine className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                Add Team Member
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-lg max-h-screen sm:max-h-[90vh]">
              <DialogHeader>
                <DialogTitle>Add a Team Member to the workspace</DialogTitle>
                <DialogDescription className="mt-1 text-sm leading-6">
                  You can only add users who have an account in the system. Invitations will be
                  supported in the future.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit(addTeamMemberOnSubmit)} className="mt-4 space-y-4">
                <div className="mt-4">
                  <Label htmlFor="role-new-user" className="font-medium">
                    Select Team Member
                  </Label>
                  <Controller
                    control={control}
                    name="userId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="role-new-user" name="role-new-user" className="mt-2">
                            <SelectValue placeholder="Select Team Member" />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {nonExistingUsers.map(user => (
                              <SelectItem key={user.id} value={user.id?.toString()}>
                                {user.email}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.userId && <ErrorMessage>{errors.userId.message}</ErrorMessage>}
                </div>

                <DialogFooter className="mt-6">
                  <DialogClose asChild>
                    <Button
                      type="button"
                      className="mt-2 w-full sm:mt-0 sm:w-fit"
                      variant="secondary"
                    >
                      Go back
                    </Button>
                  </DialogClose>
                  {/* <DialogClose asChild> */}
                  <Button
                    type="submit"
                    disabled={addOrRemoveTeamMembersMutationLoading}
                    className="w-full sm:w-fit"
                  >
                    Add Team Member
                  </Button>
                  {/* </DialogClose> */}
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <ul role="list" className="mt-6 divide-y divide-gray-200 dark:divide-gray-800">
          {workspace?.workspace_users.map(user => (
            <li key={user.user.id} className="flex items-center justify-between gap-x-6 py-2.5">
              <div className="flex items-center gap-x-4 truncate">
                <span
                  className="hidden size-9 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white text-xs text-gray-700 sm:flex dark:border-gray-800 dark:bg-gray-950 dark:text-gray-300"
                  aria-hidden="true"
                >
                  {user.user.email.charAt(0).toUpperCase()}
                </span>
                <div className="truncate">
                  <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-50">
                    {user.user.name ? user.user.name : "Team Member"}
                  </p>
                  <p className="truncate text-xs text-gray-500">{user.user.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                    >
                      <RiMore2Fill
                        className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                        aria-hidden="true"
                      />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-36">
                    <DropdownMenuItem
                      disabled={addOrRemoveTeamMembersMutationLoading}
                      onClick={() => {
                        removeTeamMember(user.user.id);
                      }}
                      className="text-red-600 dark:text-red-500"
                    >
                      Remove from workspace
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
