import { getRealtors } from "../queries/getRealtors";
// import { GetRealtorsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";
import faker from "faker";
export type UseRealtors = ReturnType<typeof useRealtors>;

export const useRealtors = () => {
  const { data, loading, error } = useQuery</*GetRealtorsQuery*/ any>(getRealtors);

  const realtors = Array(10)
    .fill(null)
    .map((_, index) => ({
      id: index + 1,
      name: "John Doe",
      email: faker.internet.email(),
    }));
  return {
    realtors, // TODO: uncomment below and above
    // realtors: data && Array.isArray(data.user) ? data.user : [],
    realtorsError: error,
    realtorsLoading: loading,
  };
};
