import { ReactElement } from "react";
import SignIn from "../../../modules/authentication/signIn/page/SignIn";
import ForgotPassword from "../../../modules/authentication/forgotPassword/page/ForgotPass";
import AccessDenied from "../../../modules/authentication/accessDenied/page/AccessDenied";
import SingleRealtorGeneral from "../../../modules/realtors/singleRealtorGeneral/page/SingleRealtorGeneral";

import SingleRealtorChannels from "../../../modules/realtors/singleRealtorChannels/page/SingleRealtorChannels";
import SingleRealtorUsers from "../../../modules/realtors/singleRealtorUsers/page/SingleRealtorUsers";
import Realtors from "../../../modules/realtors/realtors/page/Realtors";
import Dashboard from "../../../modules/dashboard/page/Dashboard";
import Zipcodes from "../../../modules/zipcodes/zipcodes/page/Zipcodes";
import SingleZipcodeGeneral from "../../../modules/zipcodes/singleZipcodeGeneral/page/SingleZipcodeGeneral";
import SingleZipcodeRealtors from "../../../modules/zipcodes/singleZipcodeRealtors/page/SingleZipcodeRealtors";

export interface Route {
  path: string;
  exact: boolean;
  main: (any: any) => ReactElement;
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/access-denied",
    main: AccessDenied,
    exact: true,
  },
];

// routes that can be access when user is inauthenticated
export const restrictedRoutes: Route[] = [
  {
    path: "/",
    main: SignIn,
    exact: true,
  },

  {
    path: "/forgot-password",
    main: ForgotPassword,
    exact: true,
  },
];

export const adminLayoutRoutes: Route[] = [
  {
    path: "/dashboard",
    main: Dashboard,
    exact: true,
  },
  {
    path: "/realtors",
    main: Realtors,
    exact: true,
  },
  {
    path: "/zipcodes",
    main: Zipcodes,
    exact: true,
  },
];

export const singleZipcodesRoutes: Route[] = [
  {
    path: "/zipcodes/:zipcodeId/general",
    main: SingleZipcodeGeneral,
    exact: true,
  },
  {
    path: "/zipcodes/:zipcodeId/realtors",
    main: SingleZipcodeRealtors,
    exact: true,
  },
];

export const adminNonLayoutRoutes: Route[] = [];

export const singleRealtorRoutes: Route[] = [
  {
    path: "/realtors/:realtorId/general",
    main: SingleRealtorGeneral,
    exact: true,
  },
  {
    path: "/realtors/:realtorId/users",
    main: SingleRealtorUsers,
    exact: true,
  },
  {
    path: "/realtors/:realtorId/channels",
    main: SingleRealtorChannels,
    exact: true,
  },
];
