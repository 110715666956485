import { gql } from "@apollo/client";

export const getZipcodesPaginated = gql`
  query getZipcodesPaginated($offset: Int!, $limit: Int!, $orderBy: [zipcode_order_by!]) {
    zipcode(limit: $limit, offset: $offset, order_by: $orderBy) {
      id
      code
      number_of_addresses
      status_id
    }

    zipcode_aggregate {
      aggregate {
        count
      }
    }
  }
`;
