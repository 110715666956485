import { useParams } from "react-router-dom";
import { Button } from "../../../../shared/components/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { RiAddLine, RiMore2Fill } from "@remixicon/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Label } from "../../../../shared/components/label/Label";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useEffect } from "react";
import React from "react";
import { useZipcodeById } from "../../../../shared/domains/postcodes/zipcodes/hooks/useZipcodeById";
import { AddRealtorsValues, addRealtorValidationSchema } from "../validation";
import { useRealtors } from "../../../../shared/domains/realtors/graphql/hooks/useRealtors";
import { Divider } from "../../../../shared/components/divider/Divider";
import { useAddOrRemoveRealtorsAPI } from "../../../../shared/domains/realtors/api/addOrRemoveRealtorsAPI";

export default function SingleZipcodeRealtors() {
  let { zipcodeId } = useParams<{ zipcodeId: string }>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { zipcode, refetchZipcode } = useZipcodeById({
    id: Number(zipcodeId),
  });
  const {
    mutateAsync: addOrRemoveRealtorsMutation,
    isSuccess: addOrRemoveRealtorsMutationSuccess,
    isLoading: addOrRemoveRealtorsMutationLoading,
  } = useAddOrRemoveRealtorsAPI();
  const { realtors } = useRealtors();
  const assignedRealtors = zipcode?.zipcode_realtors.map(realtor => realtor.id);
  const activeRealtors = zipcode?.zipcode_realtors.filter(realtor => realtor.is_active);
  const inactiveRealtors = zipcode?.zipcode_realtors.filter(realtor => !realtor.is_active);
  const nonassignedRealtors = realtors.filter(realtor => !assignedRealtors?.includes(realtor.id));

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AddRealtorsValues>({
    resolver: yupResolver(addRealtorValidationSchema),
  });

  useEffect(() => {
    if (addOrRemoveRealtorsMutationSuccess) {
      setIsDialogOpen(false);
      refetchZipcode();
      reset(undefined);
    }
  }, [addOrRemoveRealtorsMutationSuccess]);

  const addTeamMemberOnSubmit = async (data: AddRealtorsValues) => {
    await addOrRemoveRealtorsMutation({
      zipcodeId: Number(zipcodeId),
      reatorIdsToAdd: [Number(data.realtorId)],
      realtorIdsToBeActive: [],
      realtorIdsToBeInactive: [],
    });
  };

  const makeInactiveOnThisZipcode = async (realtorId: number) => {
    await addOrRemoveRealtorsMutation({
      zipcodeId: Number(zipcodeId),
      reatorIdsToAdd: [],
      realtorIdsToBeActive: [],
      realtorIdsToBeInactive: [realtorId],
    });
  };

  const makeActiveOnThisZipcode = async (realtorId: number) => {
    await addOrRemoveRealtorsMutation({
      zipcodeId: Number(zipcodeId),
      reatorIdsToAdd: [],
      realtorIdsToBeActive: [realtorId],
      realtorIdsToBeInactive: [],
    });
  };

  return (
    <>
      <section aria-labelledby="existing-realtors">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3
              id="existing-realtors"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Active Realtors
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              You can add realtors or make them inactive on this zipcode.
            </p>
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button className="mt-4 w-full gap-2 sm:mt-0 sm:w-fit">
                <RiAddLine className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                Add Realtor
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-lg max-h-screen sm:max-h-[90vh]">
              <DialogHeader>
                <DialogTitle>Add a Realtor to this zipcode</DialogTitle>
                <DialogDescription className="mt-1 text-sm leading-6">
                  You can only add realtors who has account in the system.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit(addTeamMemberOnSubmit)} className="mt-4 space-y-4">
                <div className="mt-4">
                  <Label htmlFor="role-new-user" className="font-medium">
                    Select Realtor
                  </Label>
                  <Controller
                    control={control}
                    name="realtorId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="role-new-user" name="role-new-user" className="mt-2">
                            <SelectValue placeholder="Select Team Member" />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {nonassignedRealtors.map(realtor => (
                              <SelectItem key={realtor.id} value={realtor.id?.toString()}>
                                {realtor.email}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.realtorId && <ErrorMessage>{errors.realtorId.message}</ErrorMessage>}
                </div>

                <DialogFooter className="mt-6">
                  <DialogClose asChild>
                    <Button
                      type="button"
                      className="mt-2 w-full sm:mt-0 sm:w-fit"
                      variant="secondary"
                    >
                      Go back
                    </Button>
                  </DialogClose>
                  {/* <DialogClose asChild> */}
                  <Button
                    type="submit"
                    disabled={addOrRemoveRealtorsMutationLoading}
                    className="w-full sm:w-fit"
                  >
                    Add Realtor
                  </Button>
                  {/* </DialogClose> */}
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <ul role="list" className="mt-6 divide-y divide-gray-200 dark:divide-gray-800">
          {activeRealtors.map(realtor => (
            <li key={realtor.id} className="flex items-center justify-between gap-x-6 py-2.5">
              <div className="flex items-center gap-x-4 truncate">
                <span
                  className="hidden size-9 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white text-xs text-gray-700 sm:flex dark:border-gray-800 dark:bg-gray-950 dark:text-gray-300"
                  aria-hidden="true"
                >
                  {realtor.email.charAt(0).toUpperCase()}
                </span>
                <div className="truncate">
                  <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-50">
                    {realtor.name ? realtor.name : "Realtor"}
                  </p>
                  <p className="truncate text-xs text-gray-500">{realtor.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                    >
                      <RiMore2Fill
                        className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                        aria-hidden="true"
                      />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-36">
                    <DropdownMenuItem
                      disabled={addOrRemoveRealtorsMutationLoading}
                      onClick={() => {
                        makeInactiveOnThisZipcode(realtor.id);
                      }}
                      className="text-red-600 dark:text-red-500"
                    >
                      Make inactive
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <Divider />

      <section aria-labelledby="existing-realtors">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3
              id="existing-realtors"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Inactive Realtors
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              You can reactivate realtors on this zipcode.
            </p>
          </div>
        </div>
        <ul role="list" className="mt-6 divide-y divide-gray-200 dark:divide-gray-800">
          {inactiveRealtors.map(realtor => (
            <li key={realtor.id} className="flex items-center justify-between gap-x-6 py-2.5">
              <div className="flex items-center gap-x-4 truncate">
                <span
                  className="hidden size-9 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white text-xs text-gray-700 sm:flex dark:border-gray-800 dark:bg-gray-950 dark:text-gray-300"
                  aria-hidden="true"
                >
                  {realtor.email.charAt(0).toUpperCase()}
                </span>
                <div className="truncate">
                  <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-50">
                    {realtor.name ? realtor.name : "Realtor"}
                  </p>
                  <p className="truncate text-xs text-gray-500">{realtor.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                    >
                      <RiMore2Fill
                        className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                        aria-hidden="true"
                      />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-36">
                    <DropdownMenuItem
                      disabled={addOrRemoveRealtorsMutationLoading}
                      onClick={() => {
                        makeActiveOnThisZipcode(realtor.id);
                      }}
                      className="text-red-600 dark:text-red-500"
                    >
                      Make active
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
