import { getZipcodesPaginated } from "../queries/getZipcodesPaginated";
import { useQuery } from "@apollo/client";
import * as faker from "faker";
import { ZIPCODE_STATUS } from "../../../../constants/models";
export type UseZipcodesPaginated = ReturnType<typeof useZipcodesPaginated>;

export const useZipcodesPaginated = () => {
  const { data, loading, error, refetch } = useQuery<any>(getZipcodesPaginated);

  const zipcodes = Array(10)
    .fill(null)
    .map((_, index) => ({
      id: index + 1,
      code: faker.address.zipCode(),
      number_of_addresses: faker.datatype.number({ min: 5000, max: 10000 }),
      status_id: index % 2 === 0 ? ZIPCODE_STATUS.ACTIVE : ZIPCODE_STATUS.INACTIVE,
    }));
  const zipcodesCount = zipcodes.length;
  return {
    zipcodesCount,
    zipcodes,
    // zipcodesCount: data?.zipcode_aggregate?.aggregate?.count || 0,
    // zipcodes: data && Array.isArray(data.zipcode) ? data.zipcode : [],
    zipcodesError: error,
    zipcodesLoading: loading,
    refetchZipcodes: refetch,
  };
};
