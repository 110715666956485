import {
  ArrowDownToDot,
  CircleArrowOutUpRight,
  CircleCheckBig,
  Settings,
  SquareFunction,
} from "lucide-react";
import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../shared/components/accordion/Accordion";
import { Button } from "../../../../shared/components/button/Button";
import { Divider } from "../../../../shared/components/divider/Divider";
import { cx } from "../../../../shared/utils";

const rules = [
  [
    {
      id: 1,
      type: "event",
      method: {
        title: "Addresses have been pulled",
        description: "3428 Addresses",
      },
    },
    {
      id: 2,
      type: "event",
      method: {
        title: "Owners have been pulled",
        description: "4311 Matched Owners",
      },
    },
    {
      id: 3,
      type: "event",
      method: {
        title: "Profiles have been pulled",
        description: "3818 Matched Profiles",
      },
    },
  ],
];

type Props = {
  status: string;
};

export default function ZipcodeLaunchSteps({ status }: Props) {
  return (
    <form>
      <Accordion type="single" className="mt-6 space-y-4" collapsible>
        <AccordionItem
          value="1"
          className="rounded-md border border-gray-200 px-4 dark:border-gray-800"
        >
          <AccordionTrigger className="truncate">
            <div className="flex h-8 w-full items-center justify-between gap-4 truncate">
              <span className="truncate">Launch status of zipcode</span>
              <span className="mr-6 flex items-center gap-2">
                <CircleCheckBig
                  className="size-5 shrink-0 text-emerald-600 dark:text-emerald-500"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-900 dark:text-gray-50">{status}</span>
              </span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <Divider className="my-0" />
            <ul role="list" className="mt-6 space-y-6" aria-label="Rule steps">
              {rules.map((ruleGroup, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {ruleGroup.map((rule, ruleIndex) => (
                    <li key={rule.id} className="relative flex gap-x-4">
                      <div
                        className={cx(
                          ruleIndex === ruleGroup.length - 1 ? "" : "-bottom-6",
                          "absolute left-0 top-0 flex w-9 justify-center"
                        )}
                      >
                        <div className="w-px bg-gray-200 dark:bg-gray-800" />
                      </div>
                      {rule.type === "event" ? (
                        <>
                          <span
                            className="relative flex aspect-square h-9 items-center justify-center rounded-lg bg-orange-600 dark:bg-orange-500"
                            aria-hidden="true"
                          >
                            <ArrowDownToDot
                              className="size-5 shrink-0 text-white"
                              aria-hidden="true"
                            />
                          </span>
                          <div>
                            <p className="text-sm font-medium text-gray-900 dark:text-gray-50">
                              {ruleIndex + 1}. {rule.method.title}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {rule.method.description}
                            </p>
                          </div>
                        </>
                      ) : rule.type === "function" ? (
                        <>
                          <span
                            className="relative flex aspect-square h-9 items-center justify-center rounded-lg bg-sky-500 dark:bg-sky-500"
                            aria-hidden="true"
                          >
                            <SquareFunction
                              className="size-5 shrink-0 text-white"
                              aria-hidden="true"
                            />
                          </span>
                          <div>
                            <p className="text-sm font-medium text-gray-900 dark:text-gray-50">
                              {ruleIndex + 1}. {rule.method.title}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {rule.method.description}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <span
                            className="relative flex aspect-square h-9 items-center justify-center rounded-lg bg-emerald-500 dark:bg-emerald-500"
                            aria-hidden="true"
                          >
                            <CircleArrowOutUpRight
                              className="size-5 shrink-0 text-white"
                              aria-hidden="true"
                            />
                          </span>
                          <div>
                            <p className="text-sm font-medium text-gray-900 dark:text-gray-50">
                              {ruleIndex + 1}. {rule.method.title}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {rule.method.description}
                            </p>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
            <div className="mt-6 flex items-center justify-between">
              <time
                dateTime="2023-01-23T10:32"
                className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-gray-500"
              >
                Updated 15 mins ago
              </time>
              <div></div>
              <div className="flex items-center gap-2">
                <Button variant="secondary" className="gap-2 py-1.5">
                  <Settings className="-ml-0.5 size-4 shrink-0" aria-hidden="true" />
                  View Matching Engine
                </Button>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </form>
  );
}
