import { useQuery } from "@apollo/client";
import { getZipcodeById } from "../queries/getZipcodeById";
// import {
//   GetZipcodeByIdQuery,
//   GetZipcodeByIdQueryVariables,
// } from "../../../../infrastructure/graphql/core/generatedTypes";
import faker from "faker";
export type UseZipcodeById = ReturnType<typeof useZipcodeById>;

export const useZipcodeById = ({ id }: { id: number }) => {
  // const { data, loading, error, refetch } = useQuery<
  //   // GetZipcodeByIdQuery,
  //   // GetZipcodeByIdQueryVariables
  //   any,
  //   any
  // >(getZipcodeById, {
  //   variables: {
  //     id,
  //   },
  //   skip: !id,
  // });

  const zipcode = {
    id: id,
    code: faker.address.zipCode(),
    status_id: 1,
    value: faker.commerce.price(),
    number_of_addresses: faker.random.number({
      min: 1000,
      max: 10000,
    }),
    zipcode_realtors: Array(5)
      .fill(null)
      .map((_, index) => ({
        id: index + 1,
        name: faker.name.findName(),
        email: faker.internet.email(),
        is_active: index % 2 === 0,
      })),
  };

  return {
    zipcode,
    // TODO: uncomment this
    // zipcode: data && data?.zipcode_by_pk ? data.zipcode_by_pk : null,
    zipcodeError: null, // error,
    zipcodeLoading: false, // loading,
    refetchZipcode: async () => {
      // await refetch();
    },
  };
};
