import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";

import { Badge, BadgeProps } from "../../../../shared/components/badge/Badge";
import { Ellipsis, Linkedin, Mail } from "lucide-react";

import { UseWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { OUTREACH_PLATFORM, WORKSPACE_STATUS } from "../../../../shared/constants/models";
import { ArrayElement } from "../../../../shared/utils";
import { UseOutreachAgentsByWorkspaceId } from "../../../../shared/domains/channels/graphql/hooks/useOutreachAgentsByWorkspaceId";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import dayjs from "dayjs";
import { Button } from "../../../../shared/components/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import { RiMore2Fill } from "@remixicon/react";

const columnHelper =
  createColumnHelper<ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>>();

export const getColumns = ({
  onEditClick,
  onVerificationClick,
  onActivationClick,
}: {
  onEditClick: (row: Row<ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>>) => void;
  onActivationClick: (
    row: Row<ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>>,
    type: "activate" | "deactivate"
  ) => void;
  onVerificationClick: (
    row: Row<ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>>,
    type: "verify" | "unverify"
  ) => void;
}) =>
  [
    columnHelper.accessor("platform_identifier", {
      header: ({ column }) => <TableColumnHeader column={column} title="Identifier" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Identifier",
      },
    }),
    columnHelper.accessor("outreach_platform.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Platform" />,
      enableSorting: true,
      enableHiding: false,
      cell: ({ row }) => {
        const outreachPlatformId = row.original.outreach_platform.id;

        const outreachPlatformName = row.original.outreach_platform.name;
        const isLinkedin = outreachPlatformId === OUTREACH_PLATFORM.LINKEDIN;
        const isEmail = outreachPlatformId === OUTREACH_PLATFORM.EMAIL;

        return outreachPlatformName;
      },
      meta: {
        displayName: "Platform",
      },
    }),
    columnHelper.accessor("outreach_agent_type.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Channel Type" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Channel Type",
      },
    }),

    columnHelper.accessor("campaign_external_connection_provider.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Provider" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Provider",
      },
    }),
    columnHelper.accessor("external_id", {
      header: ({ column }) => <TableColumnHeader column={column} title="External Id" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "External Id",
      },
    }),
    columnHelper.accessor("is_active", {
      header: ({ column }) => <TableColumnHeader column={column} title="Status" />,
      enableSorting: true,
      meta: {
        // className: "text-left",
        displayName: "Status",
      },
      cell: ({ row }) => {
        const statusValue = row.getValue("is_active");
        const activeStatus = statusValue === true;
        return (
          <Badge variant={activeStatus ? "success" : "error"}>
            {activeStatus ? "Active" : "Inactive"}
          </Badge>
        );
      },
    }),
    columnHelper.accessor("is_verified", {
      header: ({ column }) => <TableColumnHeader column={column} title="Verification" />,
      enableSorting: true,
      meta: {
        // className: "text-left",
        displayName: "Verification",
      },
      cell: ({ row }) => {
        const statusValue = row.getValue("is_verified");
        const activeStatus = statusValue === true;
        return (
          <Badge variant={activeStatus ? "success" : "error"}>
            {activeStatus ? "Verified" : "Unverified"}
          </Badge>
        );
      },
    }),
    // columnHelper.accessor("created_at", {
    //   header: ({ column }) => <TableColumnHeader column={column} title="Created At" />,
    //   enableSorting: true,
    //   enableHiding: false,
    //   cell: ({ getValue }) => {
    //     const date = getValue() as Date;
    //     return dayjs(date).format("DD/MM/YYYY");
    //   },
    //   meta: {
    //     displayName: "Created At",
    //     className: "tabular-nums",
    //   },
    // }),
    columnHelper.display({
      id: "edit",
      header: "Edit",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Edit",
      },
      cell: ({ row }) => {
        const isActive = row.original.is_active === true;
        const isVerified = row.original.is_verified === true;
        return (
          <div className="flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="ghost"
                  className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                >
                  <RiMore2Fill
                    className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-36  -mt-2">
                {isActive && (
                  <>
                    <DropdownMenuItem onClick={() => onEditClick(row)}>Edit</DropdownMenuItem>
                    {!isVerified && (
                      <DropdownMenuItem onClick={() => onVerificationClick(row, "verify")}>
                        Verify
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem
                      // disabled={addOrRemoveTeamMembersMutationLoading}
                      // onClick={() => {
                      //   removeTeamMember(user.user.id);
                      // }}
                      onClick={() => onActivationClick(row, "deactivate")}
                      className="text-red-600 dark:text-red-500"
                    >
                      Deactivate
                    </DropdownMenuItem>
                  </>
                )}
                {!isActive && (
                  <DropdownMenuItem
                    onClick={() => onActivationClick(row, "activate")}
                    // disabled={addOrRemoveTeamMembersMutationLoading}
                    // onClick={() => {
                    //   removeTeamMember(user.user.id);
                    // }}
                  >
                    Activate
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseOutreachAgentsByWorkspaceId["outreachAgents"]>>[];
