import { useSearchParams } from "react-router-dom";
import { UseWorkspaces } from "../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import useScroll from "../../../shared/hooks/UseScroll";
import { FilterWorkspaces } from "./FilterWorkspaces";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../shared/components/accordion/Accordion";
import { Button } from "../../../shared/components/button/Button";
import { cx } from "../../../shared/utils";
import { FilterDate } from "./FilterDate";

export interface HeaderProps {
  workspaces: UseWorkspaces["workspaces"];
}

export default function Header({ workspaces }: HeaderProps) {
  const scrolled = useScroll(10);
  const [_, setSearchParams] = useSearchParams();

  const handleResetFilters = () => {
    setSearchParams(undefined);
  };

  return (
    <section
      aria-labelledby="reports-title"
      className={cx(
        "sticky top-16 z-50 -my-6 flex flex-col gap-6 bg-white py-6 md:flex-row md:flex-wrap md:items-center md:justify-between lg:top-0 dark:bg-gray-925",
        scrolled && "border-b border-gray-200 transition-all dark:border-gray-900"
      )}
    >
      <div className="space-y-1">
        <h1 id="reports-title" className="text-lg font-semibold text-gray-900 dark:text-gray-50">
          Overview
        </h1>
        <p className="whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
          View and filter your analytics data
        </p>
      </div>
      <Accordion type="single" collapsible className="block md:hidden">
        <AccordionItem className="rounded-md border" value="1">
          <AccordionTrigger className="px-4 py-2.5">Filters</AccordionTrigger>
          <AccordionContent className="p-4">
            <div className="flex flex-col gap-3 md:flex-row md:items-end">
              <FilterDate />
              <FilterWorkspaces workspaces={workspaces} />
              {/* <FilterExpenseStatus />
              <FilterAmount /> */}
              <Button
                variant="light"
                className="h-fit dark:border-gray-800"
                onClick={handleResetFilters}
              >
                Reset
              </Button>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="hidden items-end gap-3 md:flex md:flex-wrap">
        <FilterDate />
        <FilterWorkspaces workspaces={workspaces} />
        {/* <FilterExpenseStatus />
        <FilterAmount /> */}
        <Button variant="light" className="h-fit dark:border-gray-800" onClick={handleResetFilters}>
          Reset
        </Button>
      </div>
    </section>
  );
}
