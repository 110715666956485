import { gql } from "@apollo/client";

export const getRealtorsPaginated = gql`
  query getRealtorsPaginated($offset: Int!, $limit: Int!, $orderBy: [realtor_order_by!]) {
    realtor(limit: $limit, offset: $offset, order_by: $orderBy) {
      id
      name
      email
      homeowner_network 
      status_id
    }

    realtor_aggregate {
      aggregate {
        count
      }
    }
  }
`;
