import { useState } from "react";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { ColumnSort } from "@tanstack/react-table";
import { useZipcodesPaginated } from "../../../../shared/domains/postcodes/zipcodes/hooks/useZipcodesPaginated";

export default function Zipcodes() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const { zipcodes, zipcodesCount, refetchZipcodes } = useZipcodesPaginated();
  // const { zipcodesStatuses } = useZipcodesStatuses();
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  console.log("zipcodes", zipcodes);
  // const [zipcodeActiveStatus, setZipcodesActiveStatus] = useState<number>(0);

  const { offset, setOffset } = usePagination({
    pageSize: 10,
    totalRecords: zipcodesCount,
  });

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  // const handleZipcodeStatusChange = (status: number) => {
  //   if (status > 0) {
  //     setZipcodesActiveStatus(status);
  //   } else {
  //     setZipcodesActiveStatus(0);
  //   }
  //   setOffset(0);
  //   setTriggerFilter(false);
  // };

  // let zipcodesActiveFilter = {};

  // if (zipcodeActiveStatus === 0) {
  //   zipcodesActiveFilter = {};
  // } else {
  //   zipcodesActiveFilter = {
  //     _eq: zipcodeActiveStatus,
  //   };
  // }

  // useEffect(() => {
  // refetchZipcodes({
  //     variables: {
  //       limit: 10,
  //       offset,
  //       zipcodesActiveFilter,
  //       orderBy: translateSortingQuery({ sorting }),
  //     },
  //   });
  // }, [offset, zipcodeActiveStatus, workspaceStatuses, sorting]);

  const navigate = useNavigate();
  const columns = getColumns({
    onManageClick: (row: any) => {
      console.log("Manage clicked", row);
      navigate(`/zipcodes/${row.id}/general`);
    },
  });

  // const zipcodesStatusOptions = zipcodesStatuses.map(item => ({
  //   value: item.id,
  //   label: item.name,
  // }));

  const resetFilters = () => {
    setOffset(0);
    setTriggerFilter(true);
  };

  const isFiltered = false;

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Zipcodes
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">A list of all Zipcodes.</p>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          setSorting={setSorting}
          sorting={sorting}
          isFiltered={isFiltered}
          triggerFilter={triggerFilter}
          resetFilters={resetFilters}
          data={zipcodes}
          columns={columns}
          offset={offset}
          onPaginationChange={handleOnPaginationChange}
          totalRecords={zipcodesCount}
          onRowClick={row => {
            navigate(`/zipcodes/${row.original.id}/general`);
          }}
        />
      </div>
    </>
  );
}
