import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";

import { Badge } from "../../../../shared/components/badge/Badge";

import { ZIPCODE_STATUS } from "../../../../shared/constants/models";
import { ArrayElement } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import { UseZipcodesPaginated } from "../../../../shared/domains/postcodes/zipcodes/hooks/useZipcodesPaginated";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import { Button } from "../../../../shared/components/button/Button";
import { RiMore2Fill } from "@remixicon/react";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";

const columnHelper = createColumnHelper<ArrayElement<UseZipcodesPaginated["zipcodes"]>>();

type Props = {
  onManageClick: (row: Row<ArrayElement<UseZipcodesPaginated["zipcodes"]>>) => void;
};

export const getColumns = ({ onManageClick }: Props) =>
  [
    columnHelper.accessor("code", {
      header: ({ column }) => <TableColumnHeader column={column} title="Zip" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Zip",
      },
    }),
    columnHelper.accessor("number_of_addresses", {
      header: ({ column }) => <TableColumnHeader column={column} title="Addresses" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Addresses",
      },
    }),
    columnHelper.accessor("status_id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Status" />,
      enableSorting: true,
      meta: {
        displayName: "Status",
      },
      cell: ({ row }) => {
        const statusValue = row.getValue("status_id");
        const activeStatus = statusValue === ZIPCODE_STATUS.ACTIVE;
        return (
          <Badge variant={activeStatus ? "success" : "error"}>
            {activeStatus ? "Active" : "Inactive"}
          </Badge>
        );
      },
    }),
    columnHelper.display({
      id: "edit",
      header: "Edit",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Edit",
      },
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2 z-50">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="ghost"
                  className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                >
                  <RiMore2Fill
                    className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-36 -mt-2 z-500">
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() =>
                    onManageClick(
                      row.original as unknown as Row<ArrayElement<UseZipcodesPaginated["zipcodes"]>>
                    )
                  }
                >
                  Manage
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseZipcodesPaginated["zipcodes"]>>[];
