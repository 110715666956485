import { House, User } from "lucide-react";

export const topNavigation = [{ name: "Dashboard", path: "/dashboard", icon: House }];

export const locationNavigation = [
  {
    name: "Zipcodes",
    path: "/zipcodes",
    icon: House,
  },
] as const;

export const actorsNavigation = [
  {
    name: "Realtors",
    path: "/realtors",
    icon: User,
  },
] as const;

export const orgName = "MeetMore";
