import { useSearchParams } from "react-router-dom";
import { RANGE_LABELS, RangeKey } from "../data";
import { Label } from "../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shared/components/select/Select";

export const QUERY_PARAM_DAYS_AGO = "days-ago";

const FilterDate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleValueChange = (value: RangeKey) => {
    searchParams.set(QUERY_PARAM_DAYS_AGO, value);
    setSearchParams(searchParams);
  };

  return (
    <div className="space-y-2">
      <Label htmlFor="date-range" className="font-medium">
        Date Range
      </Label>
      <Select
        value={searchParams.get(QUERY_PARAM_DAYS_AGO) ?? undefined}
        onValueChange={handleValueChange}
      >
        <SelectTrigger id="date-range" className="w-full md:w-36">
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent align="end">
          {Object.entries(RANGE_LABELS).map(([value, label]) => (
            <SelectItem key={value} value={value}>
              {label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export { FilterDate };
